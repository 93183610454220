import { FieldProps } from "formik";
import Select from "react-select";
import "./Validation.css";

 

//interface CustomSelectProps extends FieldProps {
  //options: any;
  //isMulti?: boolean;
  //className?: string;
  //placeholder?: string;
  //formData: any;
  //setFormData: (data: FormData) => void;
//}

// export const CustomSelect = ({
//   className,
//   placeholder,
//   field,
//   form,
//   options,
//   isMulti = false,
//   formData,
//   setFormData
// }: CustomSelectProps) => {
//   const onChange = (newValue: any, actionMeta: any) => { 
//     const { action, option } = actionMeta; 
//     if (action === "select-option" && option?.value === selectAllOption.value) { 
//       form.setFieldValue(
//         field.name,
//         (options as Option[]).map((item: Option) => item.value)
//       );
//       let values:any[] = [1,2];
//       // setFormData({...formData , [field.name]:values})
//     } else { 
//       form.setFieldValue(
//         field.name,
//         isMulti
//           ? (newValue as Option[]).map((item: Option) => item.value)
//           : (newValue as Option)?.value
//       ); 
//       let values:any[] =[1];
//       // setFormData({...formData , ['registration_type']:values})
//     } 
//   };

//   const getValue = () => {
//     if (options) {
//       return isMulti
//         ? options.filter(
//             (option: any) => field?.value?.indexOf(option?.value) >= 0
//           )
//         : options.find((option: any) => option?.value === field?.value);
//     } else {
//       return isMulti ? [] : ("" as any);
//     }
//   };
 
//   const selectAllOption = {
//     label: `Select all`,
//     value: "",
//   };
  
//   const getOptions = () => isMulti ? [selectAllOption, ...options] : options;

//   return (
//     <Select
//       className={className}
//       name={field.name}
//       value={getValue()}
//       onChange={onChange}
//       placeholder={placeholder}
//       options={getOptions()}
//       isMulti={isMulti}
//       styles={{
//         control: (baseStyles, state) => ({
//           ...baseStyles,
//           "&:focus": {
//             outline: 0,
//           },
//           boxShadow: "none",
//         }),
//       }}
//     />
//   );
// };
 

interface Option {
  label: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  className?: string;
  placeholder?: string;
  options: Option[];
  isMulti?: boolean;
  formData?: any;
  setFormData?: (data: any) => void;
   onCategoryChange?: (value: string | string[] | null) => void;
}

export const CustomSelect = ({
  className,
  placeholder = "Select",
  field,
  form,
  options,
  isMulti = false,
  formData,
  setFormData,
  onCategoryChange,
}: CustomSelectProps) => {

  const selectAllOption = {
    label: `Select all`,
    value: "select_all",
  };

  const placeholderOption = {
    label: placeholder,
    value: "",
  };

  const onChange = (newValue: any, actionMeta: any) => {
    const { action, option } = actionMeta;

  //   if (action === "select-option" && option?.value === selectAllOption.value) {
  //     form.setFieldValue(
  //       field.name,
  //       (options as Option[]).map((item: Option) => item.value)
  //     );
  //     if (setFormData) {
  //       setFormData({
  //         ...formData,
  //         [field.name]: (options as Option[]).map((item: Option) => item.value)
  //       });
  //     }
  //   } else if (option?.value === placeholderOption.value) {
  //     // Clear selection if placeholder "Select" is chosen
  //     form.setFieldValue(field.name, isMulti ? [] : "");
  //     if (setFormData) {
  //       setFormData({
  //         ...formData,
  //         [field.name]: []
  //       });
  //     }
  //   } else {
  //     form.setFieldValue(
  //       field.name,
  //       isMulti
  //         ? (newValue as Option[]).map((item: Option) => item.value)
  //         : (newValue as Option)?.value
  //     );
  //     if (setFormData) {
  //       setFormData({
  //         ...formData,
  //         [field.name]: isMulti
  //           ? (newValue as Option[]).map((item: Option) => item.value)
  //           : [1] // Replace this with actual logic based on your formData
  //       });
  //     }
  //   }
  // };
  let selectedValue;
  if (action === "select-option" && option?.value === selectAllOption.value) {
    selectedValue = (options as Option[]).map((item: Option) => item.value);
    form.setFieldValue(field.name, selectedValue);
  } else if (option?.value === placeholderOption.value) {
    selectedValue = isMulti ? [] : "";
    form.setFieldValue(field.name, selectedValue);
  } else {
    selectedValue = isMulti
      ? (newValue as Option[]).map((item: Option) => item.value)
      : (newValue as Option)?.value;
    form.setFieldValue(field.name, selectedValue);
  }

  if (setFormData) {
    setFormData({
      ...formData,
      [field.name]: selectedValue,
    });
  }

  if (onCategoryChange) {
    onCategoryChange(selectedValue); // Call onCategoryChange with selected value
  }
};
  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option: any) => {
            return field?.value?.includes(option?.value);
          })
        : options.find((option: any) => option?.value === field?.value);
    } else {
      return isMulti ? [] : "";
    }
  };

  const getOptions = () => {
    return isMulti
      ? [ selectAllOption, ...options]
      : [ placeholderOption, ...options];
  };
 
  return (
    <Select
      className={className}
      name={field.name}
      value={getValue() !== undefined ? getValue() : ''}
      onChange={onChange}
      placeholder={placeholder}
      options={getOptions()}
      isMulti={isMulti}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          "&:focus": {
            outline: 0,
          },
          boxShadow: "none",
        }),
      }}
    />
  );
};

export default CustomSelect;

 
