import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import "animate.css";
import TimeoutPopup from "./TimeoutPopup";
import { FireworksDisplay } from "./Fireworks";
import ResultsPopup from "./ResultsPopup";

const QuizzGame = () => {
    const [quizStarted, setQuizStarted] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds
    const [animationClass, setAnimationClass] = useState(""); // Class to control heart beat animation
    const [showTimeoutPopup, setShowTimeoutPopup] = useState(false); // State for the timeout popup
    const [userAnswer, setUserAnswer] = useState(null); // Track user's answer
    const [isCorrect, setIsCorrect] = useState(null); // Track if the answer is correct
    const [jumpQuestion, setJumpQuestion] = useState(""); // State for the jump question input
    const [correctAnswersCount, setCorrectAnswersCount] = useState(0); // Track correct answers
    const [showResultsPopup, setShowResultsPopup] = useState(false); // State for results popup
    const [showFireworks, setShowFireworks] = useState(false); // State to control fireworks display

    const quizData = [
        {
            question: "What is the capital of France?",
            answers: ["Paris", "London", "Berlin", "Madrid"],
            correctAnswer: 0,
        },
        {
            question: "What is 2 + 2?",
            answers: ["3", "4", "5", "6"],
            correctAnswer: 1,
        },
        {
            question: "Which planet is known as the Red Planet?",
            answers: ["Earth", "Mars", "Jupiter", "Saturn"],
            correctAnswer: 1,
        },
        {
            question: "What is the largest ocean on Earth?",
            answers: [
                "Atlantic Ocean",
                "Indian Ocean",
                "Arctic Ocean",
                "Pacific Ocean",
            ],
            correctAnswer: 3,
        },
        {
            question: "What is the chemical symbol for water?",
            answers: ["H2O", "O2", "CO2", "He"],
            correctAnswer: 0,
        },
    ];

    useEffect(() => {
        if (timeLeft > 0 && quizStarted) {
            const timer = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev === 1) {
                        setShowTimeoutPopup(true); // Show popup when time is up
                    }
                    setAnimationClass("animate__heartBeat");
                    return prev - 1;
                });
                setTimeout(() => {
                    setAnimationClass(""); // Reset the animation class after 1 second
                }, 1000);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [timeLeft, quizStarted]);

    const handleStartQuiz = () => {
        setQuizStarted(true);
    };

    const handleNextQuestion = () => {
        setUserAnswer(null); // Reset user answer for the next question
        setIsCorrect(null); // Reset correct answer flag
        if (currentQuestion < quizData.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
            setTimeLeft(120); // Reset the timer for the next question
            setShowTimeoutPopup(false); // Close the popup
        } else {
            setShowResultsPopup(true); // Show results popup when quiz finishes
            setShowFireworks(true); // Show fireworks when quiz ends
        }
    };

    const handleAnswerSelect = (index) => {
        setUserAnswer(index); // Set user's selected answer
        if (index === quizData[currentQuestion].correctAnswer) {
            setIsCorrect(true);
            setCorrectAnswersCount((prev) => prev + 1); // Increment correct answer count
            handleNextQuestion(); // Move to the next question after a short delay
        } else {
            setIsCorrect(false);
            setShowTimeoutPopup(true); // Show the popup with the correct answer
        }
    };
    const fireworksRef = useRef(null);
    const stopFireworks = () => {
        if (fireworksRef.current) {
            fireworksRef.current.stop();
        }
        setShowFireworks(false);
    };
    
    return (
        <div className="page-body quiz-bg d-flex justify-content-center align-items-center ">
            <div className="quiz-container text-center">
                {!quizStarted ? (
                    <div className="_1HvZ2MIJpyR9NmMRQbeokO">
                        <h2 className="animate__animated animate__fadeInDown fs-1 fw-bold">
                            OK here goes!
                        </h2>
                        <div className="my-4">
                            <h4>Have fun playing the quiz</h4>
                        </div>
                        <div
                            className="_2K6khW87cf5FWvjCSSXG9z"
                            onClick={handleStartQuiz}
                            role="button"
                            type="submit">
                            <div>
                                <img
                                    src="https://cdn.zzish.com/assets/67c7e7f850097ac0f9545fecbdf9388d.png"
                                    alt="Start"
                                />
                                <img
                                    src="https://cdn.zzish.com/assets/d7e66756274b21eb413a4a09518c7e30.svg"
                                    alt="Arrow"
                                    className="_3KSltVXtDOs8EQDEiqWLoD"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="quiz-content">
                        {/* Hide quiz content when fireworks are showing */}
                        {showFireworks ? (
                            <FireworksDisplay ref={fireworksRef} />
                        ) : (
                            <>
                                <div className="total-question">
                                    Question {currentQuestion + 1} of{" "}
                                    {quizData.length}
                                </div>

                                <div className="question-container mb-3 animate__animated animate__fadeInDown">
                                    <h2 className="fs-1">
                                        {quizData[currentQuestion].question}
                                    </h2>
                                </div>

                                <div className="timer-container">
                                    <div className="line line-left"></div>
                                    <div
                                        className={`timer animate__animated ${animationClass}`}>
                                        {Math.floor(timeLeft / 60)}:
                                        {("0" + (timeLeft % 60)).slice(-2)}
                                    </div>
                                    <div className="line line-right"></div>
                                </div>

                                <div className="answers-grid">
                                    {quizData[currentQuestion].answers.map(
                                        (answer, index) => (
                                            <div
                                                key={index}
                                                className={`option-item animate__animated ${
                                                    index < 2
                                                        ? "animate__fadeInLeftBig"
                                                        : "animate__fadeInRightBig"
                                                }`}>
                                                <Button
                                                    className={`m-2 w-100 answebtn ${
                                                        userAnswer === index
                                                            ? "bg-primary text-white answebtnfill"
                                                            : "border"
                                                    }`}
                                                    style={{
                                                        border:
                                                            userAnswer !== index
                                                                ? "1px solid #ccc"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        handleAnswerSelect(
                                                            index
                                                        )
                                                    }>
                                                    {answer}
                                                </Button>
                                            </div>
                                        )
                                    )}
                                </div>
                            </>
                        )}

                        <TimeoutPopup
                            isOpen={showTimeoutPopup}
                            toggle={() => {
                                setShowTimeoutPopup(false);
                                handleNextQuestion(); // Move to the next question after closing popup
                            }}
                            correctAnswer={
                                quizData[currentQuestion].answers[
                                    quizData[currentQuestion].correctAnswer
                                ]
                            }
                        />

                        <ResultsPopup
                            isOpen={showResultsPopup}
                            toggle={() => {
                                setShowResultsPopup(false);
                                setQuizStarted(false);
                                setCurrentQuestion(0);
                                setCorrectAnswersCount(0);
                                setTimeLeft(120);
                                stopFireworks(); 
                            }}
                            correctAnswersCount={correctAnswersCount}
                            totalQuestions={quizData.length}
                            stopFireworks={stopFireworks}  
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuizzGame;
