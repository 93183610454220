import { Row, Col, Label, FormGroup } from "reactstrap";
import CommonSwitchSpan from "../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";

const TestOrQuizSettingsForm = (props: any) => {
  const { submitErrors, errors,addquizsurveysTab,values ,setFieldValue} = props;
 // State to track if "Negative Grade" is selected
 const [selectedNegativeMarking, setSelectedNegativeMarking] = useState(
  values.negativeMakingOptional === 'zero' ? true : false
);

  const handleNegativeMarkingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedNegativeMarking(!selectedNegativeMarking)
    // setSelectedNegativeMarking(value); // Update the state with the selected value
    setFieldValue("negativeMakingOptional", value); // Update Formik field value
    setFieldValue("pointGrade", value === "negative-grade" ? -5 : 0); // Set point grade based on selection

  }; 
  const [selectedNegativeMarkingObligator, setSelectedNegativeMarkingObligator] = useState("zero1");
  const handleNegativeMarkingObligatorChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const { value } = e.target;
    setSelectedNegativeMarkingObligator(value); // Update the selected value in state
    setFieldValue("negativeMakingObligator", value); // Update the Formik field value
  };
  return (
    <>
      <Row>
        <Col className="mb-3">
          <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>Test or Quiz Settings</h4>
        </Col>
      </Row>
      <Row> 
        <Col sm="4" className="mb-3">
          <Label check>Maximum Percentage % of Wrong Answers To Stop The Test (Optional)</Label>
          <Field
            type="text"
            name="maximum-incorrect-answer"
            placeholder="Maximum Incorrect Answer"
            className="form-control"
          />
        </Col>
        <Col sm="4" className="mb-3">
         
          <Label check>
          Negative Marking for Wrong Answer for Required Questions (Optional)
          </Label>
          <Row>
            <Col sm="auto" className="mb-3 ms-4">
              <FormGroup check>
                <Field
                  id="zero"
                  name="negativeMakingOptional"
                  type="radio"
                  value="zero"
                  className="form-check-input"
                  checked={selectedNegativeMarking == false? true :false} // Check if "zero" is selected
                  onChange={handleNegativeMarkingChange} // Handle radio button change
                />
                <Label className="mb-0" for="zero" check>Zero ( 0 )</Label>
              </FormGroup>
            </Col>

            <Col sm="auto" className="mb-3 ms-4">
              <FormGroup check>
                <Field
                  id="negative-grade"
                  name="negativeMakingOptional"
                  type="radio"
                  value="negative-grade"
                  className="form-check-input"
                  checked={selectedNegativeMarking == true? true :false} // Check if "negative-grade" is selected
                  onChange={handleNegativeMarkingChange} // Handle radio button change
                />
                <Label className="mb-0" for="negative-grade" check>Negative Grade</Label>
              </FormGroup>
            </Col>
          </Row>
        </Col> 
        {values.negativeMakingOptional === 'negative-grade' && (
  <Col sm="4" className="mb-3">
    <FormGroup check>
      <Label check>Negative Marking Value <span className="txt-danger">*</span></Label>
      <Field
        className="form-control"
        type="number"
        name="pointGrade"
        placeholder="-0.5"
        step="0.5" // Allow changes in increments of 0.5
        min="-100" // Set a minimum value to allow negative numbers
        value={values.pointGrade < 0 ? values.pointGrade : -Math.abs(values.pointGrade)} // Ensure it's negative
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const value = parseFloat(e.target.value);
          // Ensure the value is a valid number and adjust to negative if necessary
          if (!isNaN(value)) {
            setFieldValue('pointGrade', value < 0 ? value : -Math.abs(value));
          }
        }}
      />
      <ErrorMessage name="pointGrade" component="span" className="invalid-feedback" />
    </FormGroup>
  </Col>
)}  
      </Row>
 
      <Row>
        <Col sm="4" className="mb-3">
          
          <Label check>
          Negative Marking for Wrong Answer for Optional Questions (Optional)
          </Label>
          <Row>
    <Col sm="auto" className="mb-3 ms-4">
      <FormGroup check>
        <Field
          id="zero1"
          name="negativeMakingObligator"
          type="radio"
          value="zero1"
          className="form-check-input"
          checked={selectedNegativeMarkingObligator === "zero1"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleNegativeMarkingObligatorChange(e, setFieldValue)
          }
        />
        <Label className="mb-0" for="zero1" check>
          Zero ( 0 )
        </Label>
      </FormGroup>
    </Col>
    <Col sm="auto" className="mb-3 ms-4">
      <FormGroup check>
        <Field
          id="negativegrade1"
          name="negativeMakingObligator"
          type="radio"
          value="negativegrade1"
          className="form-check-input"
          checked={selectedNegativeMarkingObligator === "negativegrade1"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleNegativeMarkingObligatorChange(e, setFieldValue)
          }
        />
        <Label className="mb-0" for="negativegrade1" check>
          Negative Grade
        </Label>
      </FormGroup>
    </Col>
  </Row>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>Show Explanation (Optional)</Label>
          <Row>
            <Col sm="auto" className="mb-3 ms-4">
              <FormGroup check>
                <Field
                  id="show-explanation-no"
                  name="showExplanation"
                  type="radio"
                  className="form-check-input"
                />
                <Label className="mb-0" for="show-explanation-no" check>
                  No
                </Label>
              </FormGroup>
            </Col>
            <Col sm="auto" className="mb-3 ms-4">
              <FormGroup check>
                <Field
                  id="show-explanation-yes"
                  name="showExplanation"
                  type="radio"
                  checked
                  className="form-check-input"
                />
                <Label className="mb-0" for="show-explanation-yes" check>
                  Yes
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Col> 
        <Col sm="4" className="mb-3">
          <Label check>
          Show the Correct Answer After each Question <span className="txt-danger">*</span>
          </Label>
          <br />
          <div className="d-flex">
            <div className="flex-shrink-0  text-end icon-state switch-outline">
              <CommonSwitchSpan
                color={"primary"}
                name="showAnswer"
                className={`${submitErrors && `${errors.showAnswer ? "danger" : "primary"}`}`}
                defaultChecked
              />
            </div>
            <Label className="m-l-10" check></Label>
          </div>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
          Show the List of Correct Answers After the end of the Quiz<span className="txt-danger">*</span>
          </Label>
          <br />
          <div className="d-flex">
            <div className="flex-shrink-0  text-end icon-state switch-outline">
              <CommonSwitchSpan
                color={"primary"}
                name="showAnswerList"
                className={`${submitErrors && `${errors.showAnswerList ? "danger" : "primary"}`}`}
                defaultChecked
              />
            </div>
            <Label className="m-l-10" check></Label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="mb-3">
          <Label check>
            Show Final Score <span className="txt-danger">*</span>
          </Label>
          <br />
          <div className="d-flex">
            <div className="flex-shrink-0  text-end icon-state switch-outline">
              <CommonSwitchSpan
                color={"primary"}
                name="showFinalScore"
                className={`${submitErrors && `${errors.showFinalScore ? "danger" : "primary"}`}`}
                defaultChecked
              />
            </div>
            <Label className="m-l-10" check></Label>
          </div>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Allow Retaking <span className="txt-danger">*</span>
          </Label>
          <br />
          <div className="d-flex">
            <div className="flex-shrink-0  text-end icon-state switch-outline">
              <CommonSwitchSpan
                color={"primary"}
                name="allowRetaking"
                className={`${submitErrors && `${errors.allowRetaking ? "danger" : "primary"}`}`}
                defaultChecked
              />
            </div>
            <Label className="m-l-10" check></Label>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TestOrQuizSettingsForm;
