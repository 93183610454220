import { Row, Col, Label, FormGroup } from "reactstrap";
import { ErrorMessage, Field, Form } from "formik";
import { Btn } from "../../../../AbstractElements";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import SubCategory from "../../../CommonInput/SubCategory";
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput";
import { PostContent } from "../../../../utils/Constant";
import SimpleMdeReact from "react-simplemde-editor";

const AddStudyMaterialLessonForm = (props: any) => {
    const { errors, submitErrors, setSubmitError, values, setFieldValue } =
        props;

    return (
        <Form className="form-wizard mt-3">
            <Row> 
                <Col sm="4" className="mb-3">
          <Label check>A Previous Lesson as a Reference (Optional){" "}</Label>
          <Field as="select" className="form-control" name="reference-coupon">
            <option value={""}>Select a Reference</option>
          </Field>
        </Col>
                <Col sm="4" className="mb-3">
                    <SubCategory
                        className={`${
                            submitErrors &&
                            `${errors.subcategory ? "is-invalid" : "is-valid"}`
                        }`}
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Title <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        type="text"
                        name="title"
                        placeholder="Title"
                        maxLength="140"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.title ? "is-invalid" : "is-valid"}`
                        }`}
                    />
                    <TextCounter
                        length={values?.title?.length}
                        totalLength={"140"}
                    />
                    <ErrorMessage
                        name="title"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Cover Image </Label>
                    <span className="txt-danger">*</span>
                    <FormGroup>
                        <Field
                            name="image"
                            className={`form-control`}
                            type="file"
                        />
                    </FormGroup>
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Attachment (Optional)</Label>
                    <FormGroup>
                        <Field
                            name="attachement"
                            className="form-control"
                            type="file"
                        />
                        <span className="txt-danger">
                            Only pdf, mp3, jpg, jpeg, gif, png, webp allowed.
                        </span>
                    </FormGroup>
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Homework (Optional)</Label>
                    <Field
                        as="textarea"
                        name="homework"
                        placeholder="Homework"
                        className="form-control"
                        maxLength="300"
                    />
                    <TextCounter
                        length={values?.homework?.length}
                        totalLength="300"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Keywords (Optional)</Label>
                    <TagsInput
                        name="keywords"
                        placeholder="Enter keywords"
                        setFieldValue={setFieldValue}
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Url</Label>
                    <FormGroup>
                        <Field
                            type="text"
                            name="url"
                            placeholder="URL"
                            className={`form-control ${
                                submitErrors &&
                                `${errors.url ? "is-invalid" : "is-valid"}`
                            }`}
                        />
                        <ErrorMessage
                            name="url"
                            component="span"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                </Col>
            </Row>  
            <Row> 
                <Col sm="12" md="12">
                    <FormGroup>
                        <Label className="w-100" check>
                            {'Lesson Text '}:
                        </Label>
                        <SimpleMdeReact
                            id="editor_container"
                            options={{
                                spellChecker: false,
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="justify-content-between">
                <Col sm="auto" className="mb-3">
                    <Btn
                        color="secondary"
                        type="submit"
                        onClick={() => setSubmitError(true)}>
                        Save as a Draft
                    </Btn>
                </Col>
            </Row>
        </Form>
    );
};

export default AddStudyMaterialLessonForm;
