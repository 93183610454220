import * as Yup from "yup";

export interface PersonalInformationValidationProp{
    username:string;
    full_name:string; 
    language_id: string;
    country_id: string;
    state_id: string;
    city_id: string; 
    email:string;
    recovery_email:string;
    phone_number:string;
    website:string;
    paypal_account: string;
    description:string;
    date_of_birth: string;
    upload_birthday_proof: any;
    two_factor_auth:string;
}

export const personalInformationFormInitialValue:PersonalInformationValidationProp = {
    username:"",
    full_name:"", 
    language_id: "",
    country_id: "",
    state_id: "",
    city_id: "", 
    email:"",
    recovery_email:"",
    phone_number:"",
    website:"",
    paypal_account: "",
    description:"",
    date_of_birth: "",
    upload_birthday_proof: "",
    two_factor_auth:"",
}


export const personalInformationFormSchema = Yup.object().shape({
    username:Yup.string().required("Required"),
    full_name:Yup.string().required("Required"), 
    language_id: Yup.string().required("Required"),
    country_id: Yup.string().required("Required"),
    email:Yup.string().required("Required"),
});