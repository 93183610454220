import { useEffect } from "react";
import UserBanner from "../../../Component/App/User/UserBanner";
import { Container, Col, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { DeleteMyAccount, Home, LoginPageRedirectUrl } from "../../../utils/Constant";
import { useState } from "react";
import { Formik } from "formik";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  PersonalInformationValidationProp,
  personalInformationFormInitialValue,
  personalInformationFormSchema,
} from "../../../Data/App/AccountSettings/PersonalInformation";
import PersonalInformationForm from "../../../Component/App/AccountSettings/PersonalInformation/PersonalInformationForm";
import { useStorePersonalInformationMutation } from "../../../ReduxToolkit/Reducers/AccountSettings/personalInforApiSlice";
import { toast } from "react-toastify";
import Loading from "../../../Component/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";

const PersonalInformation = () => {
  const [submitErrors, setSubmitErrors] = useState<boolean>(false);

  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state) => state.auth);
  const redirect =  LoginPageRedirectUrl;

  const [storePersonalInformationApiCall, { isLoading: storeLoading }] =
    useStorePersonalInformationMutation();

  const handleSubmit = async (values: PersonalInformationValidationProp) => {
    try {
      const formData = new FormData();
      formData.append('full_name', values.full_name);
      formData.append('language_id', values.language_id);
      formData.append('country_id', values.country_id);
      formData.append('state_id', values.state_id);
      formData.append('city_id', values.city_id);
      formData.append('email', values.email); 
      formData.append('recovery_email', values.recovery_email);
      formData.append('phone_number', values.phone_number);
      formData.append('website', values.website);
      formData.append('paypal_account', values.paypal_account);
      formData.append('description', values.description);
      formData.append('date_of_birth', values.date_of_birth);
      formData.append('upload_birthday_proof', values.upload_birthday_proof);
      formData.append('two_factor_auth', values.two_factor_auth);
      
      const response = await storePersonalInformationApiCall(formData).unwrap();
      const { success, message } : any = response;
      if (success === true) { 
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong");
    }
    setSubmitErrors(false);
  };
 

  if(storeLoading) return <Loading/>;

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={`Personal Information`} parent={Home} />
      <UserBanner />
      <Container fluid>
        <Row className="pb-2">
          <Col xl="12">
            <Formik
              initialValues={personalInformationFormInitialValue}
              onSubmit={handleSubmit}
              validationSchema={personalInformationFormSchema}
            >
              {({ errors, values, setFieldValue }) => (
                <PersonalInformationForm
                  submitErrors={submitErrors}
                  setSubmitErrors={setSubmitErrors}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              )}
            </Formik>
            <hr />
            <Col>
              <Btn color="danger" type="button">
                {DeleteMyAccount}
              </Btn>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PersonalInformation;
