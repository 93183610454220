import { Card, CardBody, Col, Collapse, Container, Row } from "reactstrap";
import {EventAndActivites, Post } from "../../../../utils/Constant";
import MediaData from "../../../../Component/App/MediaData/MediaData";
import { Home } from "react-feather";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import PostsubMenu from "../../../../Component/App/PostSubMenu/PostsubMenu";
import DragCalendar from "../../../../Component/Application/Calender/DragCalendar/DragCalendar";
import { useState } from "react";

 

const EventActivites = () => {
  const[isOpen, setIsOpen] = useState(true);
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={EventAndActivites} parent={Post} />
      <PostsubMenu/>
      <Container fluid>
      <Card> 
          <Collapse isOpen={isOpen}>
            <CardBody>
              <Row>
                <Col sm="12">
                  <Row className="flex-column flex-md-row"> 
                    <DragCalendar />
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </Container>
    </div>
  );
};

export default EventActivites;
