import { Col } from 'reactstrap'
import {  P } from '../../../../AbstractElements';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../ReduxToolkit/Hooks'; 
import { CreateNewProjects } from '../../../../utils/Constant';
import { FeatherIcons } from '../../../../AbstractElements'

const ProjectListHeaderSubmenu = () => {
    
    const { mobileView } = useAppSelector((state) => state.layout);
    const location = useLocation();
    const currentPath = location.pathname;

    const isActive = (path) => currentPath === path;
    return (
        <>
      
        <Col md="12" className='p-0'>
            
            {mobileView ? (
                <div className='py-2'>
                    <span
                        className={`mx-1 px-1 border ${isActive(`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list`) ? 'bg-gray' : 'border-primary'}`}
                    >
                        {`Projects List`}
                    </span>
                    <span
                        className={`mx-1 px-1 border ${isActive(`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/task-list`) ? 'bg-gray' : 'border-primary'}`}
                    >
                        {`Tasks`}
                    </span>
                    <span
                        className={`mx-1 px-1 border ${isActive(`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/to-do-list`) ? 'bg-gray' : 'border-primary'}`}
                    >
                        {`To Dos`}
                    </span>
                </div>
            ) : (
                <>
              
                <P className='d-flex align-items-center my-0'>
                <Link className="" to={`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/new-project`} >
               <span className={`d-flex gap-1 align-items-center border rounded p-2 m-1 text-white ${isActive(`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/new-project`) ? 'bg-gray' : 'bg-dark'} ps-2 pe-2`}> <FeatherIcons iconName='PlusSquare'  /> {CreateNewProjects}</span>
       
      </Link>
                    <Link
                        to={`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list`}
                    >
                        <span className={`border rounded p-2 m-1 text-white ${isActive(`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list`) ? 'bg-gray' : 'bg-dark'} ps-2 pe-2`}>
                            {`Projects List`}
                        </span>
                    </Link>
                    <Link
                        to={`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/task-list`}
                    >
                        <span className={`border rounded p-2 m-1 text-white ${isActive(`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/task-list`) ? 'bg-gray' : 'bg-dark'} ps-2 pe-2`}>
                            {`Tasks`}
                        </span>
                    </Link>
                    <Link
                        to={`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/to-do-list`}
                    >
                        <span className={`border rounded p-2 m-1 text-white ${isActive(`${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/to-do-list`) ? 'bg-gray' : 'bg-dark'} ps-2 pe-2`}>
                            {`To Dos`}
                        </span>
                    </Link>
                </P>
                </>
                
            )}
        </Col>
        </>
                 
    )
}

export default ProjectListHeaderSubmenu