import React from "react";
import { BrandTools, CreateAPost, Post } from "../../../../utils/Constant";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CreatePostContainer from "../../../../Component/App/Post/CreatePostContainer";
import PostsubMenu from "../../../../Component/App/PostSubMenu/PostsubMenu";
import { ManagePostsTableAction } from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import { Card, CardBody, Container } from "reactstrap";

const CreatePost = () => {
    const location = useLocation();
    return (
        <div className="page-body">
            <Breadcrumbs
                mainTitle={`${CreateAPost} (${BrandTools})`}
                parent={Post}
            />
            <SubMenu />
            <Container fluid>
            <div className="mt-2">
            {ManagePostsTableAction.slice(0, 2).map((action, index) => {
                const isActive = location.pathname === `/${action.path}`; // Check if the path matches
                return (
                    <Link
                        to={`${process.env.PUBLIC_URL}/${action.path}`}
                        key={index}
                    >
                        <span
                            className={`border rounded p-2 m-1 text-white ps-2 pe-2 ${isActive ? 'bg-gray' : 'bg-dark'}`}
                        >
                            {action.actionName}
                        </span>
                    </Link>
                );
            })}
                    </div> 
            </Container>
            <CreatePostContainer />
        </div>
    );
};

export default CreatePost;
