// TimeoutPopup.js
import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const TimeoutPopup = ({ isOpen, toggle, correctAnswer }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
            <ModalHeader toggle={toggle}>Time's Up!</ModalHeader>
            <ModalBody>
                <p>The correct answer was: {correctAnswer}</p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Next Question
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default TimeoutPopup;
