import { useState } from "react";
import UserBanner from "../../../Component/App/User/UserBanner";
import { Col, Container, Row } from "reactstrap";
import { Home } from "../../../utils/Constant";
import { useEffect } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Loading from "../../../Component/Loading/Loading";
import PasswordForm from "./PasswordForm";
import {
  PasswordFormValidationProp,
  passwordFormInitialValue,
  passwordFormSchema,
} from "../../../Data/App/AccountSettings/Password";
import { useChangePasswordMutation } from "../../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";

const Password = () => {
  const [submitErrors, setSubmitErrors] = useState<boolean>(false);

  const [changePasswordApi, { isLoading }] = useChangePasswordMutation();

  const handleSubmit = async (values: PasswordFormValidationProp) => {
    setSubmitErrors(false);
    try {
      const response = await changePasswordApi(values).unwrap();
      const { success, message } = response;
      if (success === true) {
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={`Password`} parent={Home} />
      <UserBanner />
      <Container fluid>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={passwordFormInitialValue}
              onSubmit={handleSubmit}
              validationSchema={passwordFormSchema}
            >
              {({ errors, values, setFieldValue }) => (
                <PasswordForm
                  submitErrors={submitErrors}
                  setSubmitErrors={setSubmitErrors}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Password;
