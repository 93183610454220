import { useState } from "react";
import { Row, Col, Label, FormGroup, Button } from "reactstrap";
import { Btn, H3, H4, H5 } from "../../../../AbstractElements";
import CommonSwitchSpan from "../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import SubCategory from "../../../CommonInput/SubCategory";
import CommonModal from "../../../../Component/Ui-Kits/Modal/Common/CommonModal";
import { ErrorMessage, Field, Form, FieldArray, useFormikContext } from "formik";
import StudyMaterialLessonMain from "../StudyMaterialLesson/StudyMaterialLessonMain";
import QuizMaterialMain from "../QuizMaterial/QuizMaterialMain";
import TextCounter from "../../../CommonInput/TextCounter";
import AlternativeQuestions from "../../../CommonInput/AlternativeQuestions";
import AnswerInput from "../../../CommonInput/AnswerInput";
import CorrectAnswerField from "./CorrectAnswerField";
import { X } from "react-feather";
import AddAnswersMain from "./AddAnswersMain";

const AddQuestionsForm = (props: any) => {
    const [currectAnswerCount, setCurrectAnswerCount] = useState(["1"]);
    const [currecQuestionCount, setCurrectcurrecQuestionCount] = useState([
        "0",
    ]);

    const addCurrectAnswerFieldCountHandler = (arrayHelpers: any) => {
        const value = currectAnswerCount.length + 1;
        setCurrectAnswerCount((oldArray) => [...oldArray, value.toString()]);
        arrayHelpers.push("");
    };
    const addCurrecQuestionCountHandler = (arrayHelpers: any) => {
        const value = currecQuestionCount.length + 1;
        console.log(value);
        
        setCurrectcurrecQuestionCount((oldArray) => [
            ...oldArray,
            value.toString(),
        ]);
        arrayHelpers.push("");
    };

    const removeCurrectAnswerFieldHandler = (
        value: string,
        arrayHelpers: any,
        index: number
    ) => {
        const updatedFormCount = currectAnswerCount.filter(
            (item) => item !== value
        );
        setCurrectAnswerCount(updatedFormCount);
        arrayHelpers.remove(index);
    };
    const removeCurrecQuestionCountHandler = (
        value: string,
        arrayHelpers: any,
        index: number
    ) => {
        const updatedFormCount = currecQuestionCount.filter(
            (item) => item !== value
        );
        setCurrectcurrecQuestionCount(updatedFormCount);
        arrayHelpers.remove(index);
    };

    // Quiz Material popup.
    const [openModal, setOpenModal] = useState(false);
    const openModalToggle = () => setOpenModal(!openModal);

    // Study Material & Lesson popup.
    const [openSMLModal, setOpenSMLModal] = useState(false);
    const openSMLModalToggle = () => setOpenSMLModal(!openSMLModal);

    const { errors, submitErrors, setSubmitError, values } = props;
    console.log(values);
    
    const [showOptions, setShowOptions] = useState(false);
    const [showinputanswer, setshowinputanswer] = useState(true);

  // Toggle function for the answer type options
  const toggleAnswerType = () => {
    console.log(showOptions);
    
    setShowOptions(prevShowOptions => !prevShowOptions); // Toggles between showing and hiding options
  };
    const {  setFieldValue    } = useFormikContext();
    return (
        <Form className="form-wizard">
            <Row>
                {props?.addQuizMaterial && (
                    <Col sm="4" className="mb-3">
                        <Label check>Quiz Material (Optional)</Label>
                        <FormGroup>
                            <Field
                                as="select"
                                name="lesson"
                                className="form-control mb-2">
                                <option value={""}>
                                    Select a Quiz Material{" "}
                                </option>
                            </Field>
                            <span
                                className="btn btn-primary mb-2"
                                onClick={openModalToggle}>
                                <i className="fa fa-plus-circle"></i> New Quiz
                                Material
                            </span>
                        </FormGroup>
                        <CommonModal
                            isOpen={openModal}
                            toggle={openModalToggle}
                            heading="Login"
                            size="xl"
                            sizeTitle="Add a Quiz Material">
                            <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                                {" "}
                                <QuizMaterialMain addQuestions={false} />
                            </div>
                        </CommonModal>
                    </Col>
                )}
            </Row>
            <FieldArray
                name="answers"
                render={(arrayHelpers) => {
                    return (
                        <>
                            {currecQuestionCount.map(
                                (questionItem, questionIndex) => (
                                    <>
                                        <Row>
                                          <H4 className="my-2 text-muted">{`Add Question: ${questionIndex + 1}`}</H4>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Question Text{" "}
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <Field
                                                    as="textarea"
                                                    name="questionText"
                                                    placeholder="Question Text"
                                                    maxLength="300"
                                                    className={`form-control ${
                                                        submitErrors &&
                                                        `${
                                                            errors.questionText
                                                                ? "is-invalid"
                                                                : "is-valid"
                                                        }`
                                                    }`}
                                                />
                                                <TextCounter
                                                    length={
                                                        values?.questionText
                                                            ?.length
                                                    }
                                                    totalLength={"300"}
                                                />
                                                <ErrorMessage
                                                    name="questionText"
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Question Image (Optional)
                                                </Label>
                                                <FormGroup>
                                                    <Field
                                                        className="form-control"
                                                        name="questionImage"
                                                        type="file"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Question Audio (Optional)
                                                </Label>
                                                <FormGroup>
                                                    <Field
                                                        className="form-control"
                                                        name="questionAudio"
                                                        type="file"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Duration in Minutes{" "}
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <Field
                                                    type="text"
                                                    name="durationInMinutes"
                                                    placeholder="Duration in Minutes"
                                                    className={`form-control ${
                                                        submitErrors &&
                                                        `${
                                                            errors.durationInMinutes
                                                                ? "is-invalid"
                                                                : "is-valid"
                                                        }`
                                                    }`}
                                                />
                                                <ErrorMessage
                                                    name="durationInMinutes"
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Point / Grade{" "}
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <Field
                                                    type="text"
                                                    name="pointGrade"
                                                    placeholder="Point / Grade"
                                                    className={`form-control ${
                                                        submitErrors &&
                                                        `${
                                                            errors.pointGrade
                                                                ? "is-invalid"
                                                                : "is-valid"
                                                        }`
                                                    }`}
                                                />
                                                <ErrorMessage
                                                    name="pointGrade"
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Obligatory Question{" "}
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="obligationQuestion"
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.obligationQuestion
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                           {showinputanswer    && <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Answer Input{" "}
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <AnswerInput
                                                    name="answerInput"
                                                    className={`form-control ${
                                                        submitErrors &&
                                                        `${
                                                            errors.answerInput
                                                                ? "is-invalid"
                                                                : "is-valid"
                                                        }`
                                                    }`}
                                                />
                                                <ErrorMessage
                                                    name="answerInput"
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>}
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Show Calculator
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="showCalculator"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Show Translator
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="showTranslator"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Show Dictionary
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="showDictionary"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Search the Web
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="searchTheWeb"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Alternative Questions
                                                    (Optional)
                                                </Label>
                                                <AlternativeQuestions
                                                    name="aleternativeQuestions"
                                                    isMulti={true}
                                                    placeholder="Please select alternative questions"
                                                />
                                            </Col>
                                            <Col sm="6" className="mb-3">
      <Label check>Answering Type</Label>
      <Row>
        {/* Answering Type Options */}
        <Col sm="auto" className="mb-3 ms-4">
          <FormGroup check>
          <Field
                    id="select4Options"
                    name="answerInput"
                    type="radio"
                    value="multiple-choice"
                    className="form-check-input"
                    checked={values.answerInput === "multiple-choice"}
                    onChange={() => {
                      setFieldValue("answerInput", "multiple-choice");
                      setShowOptions(true);  
                      setshowinputanswer(false);  

                    }}
                  />
            <Label
              className={`mb-0 ${
                submitErrors &&
                `${errors.formType ? "text-danger" : "text-success"}`
              }`}
              htmlFor="select4Options"
              
              check
            >
              Select 1 option from 4 Options
            </Label>
          </FormGroup>
        </Col>
        <Col sm="auto" className="mb-3 ms-4">
                <FormGroup check>
                  <Field
                    id="textInput"
                    name="answerInput"
                    type="radio"
                    value="text"
                    className="form-check-input"
                    checked={values.answerInput === "text"}
                    onChange={() => {
                      setFieldValue("answerInput", "text");
                      setshowinputanswer(true);  
                      setShowOptions(false);  

                    }}
                  />
                  <Label className="mb-0" htmlFor="textInput" check>
                    Enter an Answer
                  </Label>
                </FormGroup>
              </Col> 
      </Row>

      {/* Conditionally render options if 'Select 1 option from 4 Options' is selected */}
      {showOptions && (
               <AddAnswersMain showOptions={showOptions}/>
            )}
    </Col>
                                        </Row>
                                       { showinputanswer    && <>
                                                                        <CorrectAnswerField
                                                                             
                                                                            submitErrors={
                                                                                submitErrors
                                                                            }
                                                                            errors={
                                                                                errors
                                                                            }
                                                                             
                                                                             
                                                                            values={
                                                                                values
                                                                            }
                                                                        />
                                                                       
                                                                    </>}
                                         
                                        <Row className="justify-content-between">
                                            <Col sm="auto" className="mb-3">
                                                {currecQuestionCount.length >
                                                    1 && (
                                                    <span
                                                        onClick={() =>
                                                            removeCurrecQuestionCountHandler(
                                                                questionItem,
                                                                arrayHelpers,
                                                                questionIndex
                                                            )
                                                        }
                                                        className="badge b-ln-height badge-danger">
                                                        <i className="fa fa-minus"></i>
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                )
                            )}

                            <div className="">
                                <Col sm="auto" className="mb-3">
                                    <Btn
                                        color="secondary"
                                        type="button"
                                        onClick={() =>
                                            addCurrecQuestionCountHandler(
                                                arrayHelpers
                                            )
                                        }>
                                        Add another Question
                                    </Btn> 
                                </Col>
                                
                            </div>
                        </>
                    );
                }}
            />
        </Form>
    );
};

export default AddQuestionsForm;
