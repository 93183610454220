import { fas } from "@fortawesome/free-solid-svg-icons";
import { AddKnowledgelessonNav, AddProductNav } from "../../../Types/Application/Ecommerce/AddProduct";

export const addProductNav: AddProductNav[] = [
  {
    id: 1,
    icon: "product-category",
    title: "General Info",
    detail: "Category, name, inventory number and keywords",
  },
  {
    id: 2,
    icon: "product-detail",
    title: "Details",
    detail: "Fill the details about the product or service",
  },
  {
    id: 3,
    icon: "product-gallery",
    title: "Gallery",
    detail: "Upload images of the product or service",
  }, 
  {
    id: 4,
    icon: "advance",
    title: "Branch and Price Setup",
    detail: "Select branches, add prices and addons",
  },
];
export const addQuizNav: AddKnowledgelessonNav[] = [

  {
    id: 1, 
    iconfont: fas.faClipboardQuestion, 
    icon:"",
    title: "Add a Quiz or Survey (required)",
    detail: "Add a quiz or survey information and settings",
  }, 
  {
    id: 2,
    icon:'',
    iconfont: fas.faClipboardQuestion,
    iconfont: fas.faPersonChalkboard,
    title: "Add a Lesson (optional)",
    detail: "Add a lesson to help participants get ready for the test or quiz.",
  },
  {
    id: 3,
    icon: "product-category",
    iconfont:'',
    title: "Add Special Parameters (optional)",
    detail: "Year, country, state, course, book, official exam name etc…",
  },
  {
    id: 4,
    icon: "",
    iconfont:fas.faFlaskVial,
    title: "Add Questions (required)",
    detail: "Add questions of the desired quiz or survey.",
  },
];
export const addUploadProductNav: AddProductNav[] = [
  {
    id: 1,
    icon: "product-category",
    title: "Upload Contents",
    detail: "Select upload type, usage license and start uploading files.",
  }, 
  {
    id: 2,
    icon: "product-gallery",
    title: "Add Details",
    detail: "Click 'Uploading is Completed' button, then specify the category, description and keywords for each content.   ",
  },  
];

export const typesOfProductData = [
  {
    id: "radio-icon",
    check: true,
    title: "Fixed Price",
  },
  {
    id: "radio-icon4",
    title: "BOGO(Buy one, Get one)",
  },
  {
    id: "radio-icon5",
    title: "Seasonal or holiday discount",
  },
  {
    id: "radio-icon6",
    title: "Percentage-based discount(%)",
  },
  {
    id: "radio-icon7",
    title: "Volume or bulk discount",
  },
];

export const productFiveNavData = [
  "Inventory",
  "Additional Options",
  "Shipping",
];
