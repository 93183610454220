import { Col, Input, Label } from "reactstrap";
import { Btn, FeatherIcons, H4, LI, P, UL } from "../../../../AbstractElements";
import {
    CalendarFilter,
    DraggableEvents,
    Href,
    RemoveAfterDrop,
} from "../../../../utils/Constant";
import { calenderInitialData } from "../../../../Data/Application/Calendar/Calendar";
import { useState } from "react";
import { Link } from "react-router-dom";
function getDate(dayString) {
    const today = new Date();
    const year = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();

    if (month.length === 1) {
        month = "0" + month;
    }

    return dayString.replace("YEAR", year).replace("MONTH", month);
}
const adminDropdown = [
    {
        name: "Reservation Type",
        children: [
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "All Activities",
                id: "1",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-09T12:00:00+00:00"), // Set a specific date and time
                end: getDate("2024-09-16T12:00:00+00:00"),
            },
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Activities, I created",
                id: "1",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-09T12:00:00+00:00"), // Set a specific date and time
                end: getDate("2024-09-16T12:00:00+00:00"),
            },
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Activities, I attended ",
                id: "2", // Unique ID
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-10T12:00:00+00:00"), // Different time
                end: getDate("2024-09-17T12:00:00+00:00"),
            },
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Reservations, I made",
                id: "2", // Unique ID
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-10T12:00:00+00:00"), // Different time
                end: getDate("2024-09-17T12:00:00+00:00"),
            },
        ],
    },
    {
        name: "Activity Types",
        children: [
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Appointments",
                id: "3",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-11T10:00:00+00:00"), // Specific time for this event
                end: getDate("2024-09-18T11:00:00+00:00"),
            },
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Class Rooms",
                id: "4",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-12T09:00:00+00:00"),
                end: getDate("2024-09-19T10:00:00+00:00"),
            },
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Work Shifts",
                id: "5",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-13T08:00:00+00:00"),
                end: getDate("2024-09-20T17:00:00+00:00"),
            },
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Events",
                id: "6",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-14T15:00:00+00:00"),
                end: getDate("2024-09-21T18:00:00+00:00"),
            },
        ],
    },
    {
        name: "Location",
        children: [
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Online",
                id: "7",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-15T10:00:00+00:00"),
                end: getDate("2024-09-22T12:00:00+00:00"),
            },
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "City",
                id: "8",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-16T14:00:00+00:00"),
                end: getDate("2024-09-23T16:00:00+00:00"),
            },
        ],
    },
    {
        name: "Open For",
        children: [
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Any Person",
                id: "9",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-17T13:00:00+00:00"),
                end: getDate("2024-09-24T15:00:00+00:00"),
            },
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Followers Only",
                id: "10",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-18T11:00:00+00:00"),
                end: getDate("2024-09-25T13:00:00+00:00"),
            },
            {
                backgroundColor: "#378006",
                borderColor: "#378006",
                title: "Invited Users only",
                id: "11",
                icon: "fa fa-birthday-cake",
                start: getDate("2024-09-19T16:00:00+00:00"),
                end: getDate("2024-09-26T17:00:00+00:00"),
            },
        ],
    },
];

const CalendarEvents = () => {
    const [openCalender, setOpenCalender] = useState(false);
    const [activeAccordion, setActiveAccordion] = useState(null);

    // Toggle the accordion item
    const handleAccordionToggle = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    // Handle user selection (example function)
    const handleUserSelect = (userName) => {
        console.log(`User selected: ${userName}`);
    };

    // Handle click (example function)
    const handleClick = () => {
        // Logic for click action
    };
    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    const handleCheckboxChange = (label: string) => {
        setCheckedItems((prevState) => {
            const isChecked = prevState.includes(label);
            const updatedItems = isChecked
                ? prevState.filter((item) => item !== label)
                : [...prevState, label];

            console.log(`Checked Items: ${updatedItems}`);
            return updatedItems;
        });
    };
    return (
        <Col xxl="2" md={2} lg={3} className="box-col-4-end">
            <div className="md-sidebar mb-3">
                <Btn
                    tag="a"
                    color="primary"
                    className="md-sidebar-toggle"
                    href={Href}
                    onClick={() => setOpenCalender(!openCalender)}>
                    {CalendarFilter}
                </Btn>
                <div
                    className={`md-sidebar-aside job-left-aside custom-scrollbar ${
                        openCalender ? "open" : ""
                    }`}>
                    <div id="external-events" className="calendar-default">
                        {/* <H4 className='mb-1'>{DraggableEvents}</H4> */}
                        <div className="external-events-list">
                            {/* {calenderInitialData.events.map((event, index) => (
                                <div
                                    className={`fc-event       text-sm mb-1 border-0  `}
                                    title={event.title}
                                    key={index}>
                                    <div className=" ">
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={event.title}
                                                onChange={() =>
                                                    handleCheckboxChange(
                                                        event.title
                                                    )
                                                }
                                                checked={checkedItems.includes(
                                                    event.title
                                                )}
                                            />
                                            {` ${event.title}`}
                                        </label>
                                    </div>
                                </div>
                            ))} */}
                        

                        <UL className="profile_drop simple-list show_profile_drop profile-dropdown">
                            {adminDropdown.map((data, index) => (
                                <LI key={index} className="mb-0">
                                    <div
                                        onClick={() =>
                                            handleAccordionToggle(index)
                                        }
                                        className="d-flex align-items-center justify-content-between text-sm border-0">
                                        <div className="d-flex align-items-center">
                                            <H4 className="mb-1">
                                                {data.name}
                                            </H4>
                                        </div>
                                        <FeatherIcons
                                            className="text-white"
                                            iconName={
                                                activeAccordion === index
                                                    ? "ChevronDown"
                                                    : "ChevronRight"
                                            }
                                        />
                                    </div>

                                    <UL className="pt-1">
                                        {data.children.map(
                                            (label, childIndex) => (
                                                <LI
                                                    key={childIndex}
                                                    className="fc-event"  title={label.title}>
                                                    <label>
                                                        {label.title !==
                                                        "City" ? ( // Change to match the title property
                                                            <input
                                                                type="checkbox"
                                                                value={
                                                                    label.title
                                                                } // Use the title for the value
                                                                onChange={() =>
                                                                    handleCheckboxChange(
                                                                        label.title
                                                                    )
                                                                }
                                                                checked={checkedItems.includes(
                                                                    label.title
                                                                )}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        {` ${label.title}`}{" "}
                                                        {/* Use title for display */}
                                                    </label>
                                                </LI>
                                            )
                                        )}
                                    </UL>
                                </LI>
                            ))}
                        </UL>
                        </div>  
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default CalendarEvents;
