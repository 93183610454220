import { useRef } from 'react';
import { Fireworks as FireworksComponent } from '@fireworks-js/react';  // Renamed the imported component
import type { FireworksHandlers } from '@fireworks-js/react'; 
export function FireworksDisplay() {  // Renamed the function
  const ref = useRef<FireworksHandlers>(null);

  const toggle = () => {
    if (!ref.current) return;
    if (ref.current.isRunning) {
      ref.current.stop();
    } else {
      ref.current.start();
    }
  };

  return (
    <>
      <div
        style={{ display: 'flex', gap: '4px', position: 'absolute', zIndex: 1 }}
      >
        {/* <button onClick={() => toggle()}>Toggle</button>
        <button onClick={() => ref.current!.clear()}>Clear</button> */}
      </div>
      <FireworksComponent  // Using the renamed component here
        ref={ref}
        options={{
          opacity: 0.5,
          sound: {
            enabled: true, 
            files: [
              'assets/audio/firework_1.mp3',
              'assets/audio/firework_2.mp3',
              'assets/audio/firework_3.mp3'
            ],
            volume: {
              min: 50,
              max: 50
            }
          }
        }}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'fixed',
          background: '#000',
        }}
      />
    </>
  );
}
