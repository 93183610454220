// import { Button, Col, TabContent, TabPane } from 'reactstrap';
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { setActiveTab } from "../../../../ReduxToolkit/Reducers/tabSlice";
import SecondLesson from "./SecondLesson";
import FirstSpecialPerameter from "./FirstSpecialPerameter";
import ThirdQuizSurvey from "./ThirdQuizSurvey";
import FourQuestionTab from "./FourQuestionTab";
import { Button, Col, TabContent, TabPane } from "reactstrap";
import { SVG } from "../../../../AbstractElements";

const TabAddQuizzRightSide = () => {
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state) => state.tabs.activeTab);
    const totalTabs = 4;

    // Handle "Next" and "Previous" button clicks
    const nextTab = () => {
        if (activeTab < totalTabs) {
            dispatch(setActiveTab(activeTab + 1));
        }
    };

    const previousTab = () => {
        if (activeTab > 1) {
            dispatch(setActiveTab(activeTab - 1));
        }
    };
    const handleSave = () => {
        console.log("All steps saved!");
    };
    return (
        <Col
            xxl="8"
            xl="8"
            className="box-col-8 flex-fill mt-1 ms-xl-2 position-relative">
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <ThirdQuizSurvey />
                </TabPane>
                <TabPane tabId={2}>
                    <SecondLesson />
                </TabPane>
                <TabPane tabId={3}>
                    <FirstSpecialPerameter />
                </TabPane>
                <TabPane tabId={4}>
                    <FourQuestionTab />
                </TabPane>
            </TabContent>

            <div className="position-relative end-0 bottom-0 quizz-survey-buttons">
                {activeTab > 1 && (
                    <Button
                        color="primary"
                        onClick={previousTab}
                        className="text-white  ">
                        Previous
                    </Button>
                )}
                {activeTab < totalTabs ? (
                    <Button onClick={nextTab} color="primary" className="ms-2 ">
                        <div className="d-flex align-items-center gap-sm-2 gap-1 text-white">
                            Next <SVG iconId="front-arrow" />
                        </div>
                    </Button> // Show "Next" button if not on the last tab
                ) : (
                    <Button
                        color="primary"
                        className="ms-2 text-white "
                        onClick={handleSave}>
                        Save All Steps
                    </Button> // Show "Save All Steps" on the last tab
                )}
            </div>
        </Col>
    );
};

export default TabAddQuizzRightSide;
