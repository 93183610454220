import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import "animate.css";
import { Col, Row } from "reactstrap";
import { H4, Image } from "../../AbstractElements";
import { dynamicImage } from "../../Service";
 

const NextScreenQuizzAnimation  = (props : any) => {
  const {animation,title} = props
  const [selectedAnimation, setSelectedAnimation] = useState<string>("");

  const handleAnimationChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: (field: string, value: any) => void) => {
    const value = e.target.value;
    setSelectedAnimation(""); // Clear current animation
    setFieldValue("animation", value); // Update Formik state
    setTimeout(() => setSelectedAnimation(`animate__${value}`), 100); // Apply new one with a delay
  };

  return (
    <>
  
    <Row  >
      <Col > 
        <div className={`animate__animated ${selectedAnimation}`} style={{ height: "200px", marginBottom: "20px" }}>
  
          <Image  className="img-fluid rounded-circle h-100" src={dynamicImage('young.png')} alt="user"  />

        </div>
      </Col> 
      <Col >
        <Formik
          initialValues={{ animation: "" }}
          onSubmit={() => {}}
        >
          {({ setFieldValue }) => (
            <Form>
              <div>
                <label htmlFor="animation">Select Animation:</label>
                <Field
                  as="select"
                  name="animation"
                  className='form-control'
                  onChange={(e) => handleAnimationChange(e, setFieldValue)} // Pass setFieldValue to the handler
                >
                  <option value="">-- Select an animation --</option>
                  {animation.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </Field>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
    </>
  );
};

export default NextScreenQuizzAnimation;
