import {useState} from 'react'
import { Card, CardBody,CardHeader,Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import {BranchesContactColumnData, BranchesContactWhatsAppColumnData, BranchesEmailColumnData, supportTicketData } from '../../../../../Data/Tools/BrandSupportTools/ButtonActions'
import {BranchsAction } from '../../../../../Data/Tools/ManageContents/ManageContentButtons'
import { ContactNoList, EmailList, Href, WhatsappNoList } from '../../../../../utils/Constant'
import SubMenu from '../../../../../Component/Application/SubMenu/SubMenu'
import BranchSubMenu from '../BranchSubMenu/BranchSubMenu'
import ManageOnlineSubMenu from '../../ManageOnlineSubMenu/ManageOnlineSubMenu'

const BranchPublicContact = () => {
    const [basicTab, setBasicTab] = useState<string>("1");
    const toggleDelete = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const handleRowSelected =() =>{}
    return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`New Branche public Contact (Manage Online Store)`} parent={`Manage Store`} />
      <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
      <Container fluid> 
        <Row>
          <Col sm="12">
          <div className='ms-1 mt-2'>
          <BranchSubMenu/>

                  </div> 
            <Card > 
              <CardBody className='p-2'>
                <>
                  <Card className='shadow-none'>
                  <Nav pills className="nav-warning ">
                    <NavItem>
                      <NavLink
                        href={Href}
                        className={basicTab === "1" ? "bg-gray text-white" : ""}
                        onClick={() => setBasicTab("1")}
                      >
                        {WhatsappNoList}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={Href}
                        className={basicTab === "2" ? "bg-gray text-white" : ""}
                        onClick={() => setBasicTab("2")}
                      >
                        {ContactNoList}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={Href}
                        className={basicTab === "3" ? "bg-gray text-white" : ""}
                        onClick={() => setBasicTab("3")}
                      >
                        {EmailList}
                      </NavLink>
                    </NavItem>

                    {/* <NavItem className='w-50'>
                      <Link to={`${process.env.PUBLIC_URL}/manage-online-store/manage-branches/branch-public-contacts/add-new`}><Btn className='bg-primary float-end'>{`Add a Branch Contact`}</Btn></Link>
                    </NavItem> */}
                  </Nav>

                  </Card>
                  <TabContent activeTab={basicTab}>
                    <TabPane tabId={"1"} >
                      <div className="table-responsive">
                        <DataTable className='custom-scrollbar' columns={BranchesContactWhatsAppColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                      </div>
                    </TabPane>
                    <TabPane tabId={`2`} >
                      <div className="table-responsive">
                        <DataTable className='custom-scrollbar' columns={BranchesContactColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                      </div>
                    </TabPane>
                    <TabPane tabId={`3`} >
                      <div className="table-responsive">
                        <DataTable className='custom-scrollbar' columns={BranchesEmailColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                      </div>
                    </TabPane>
                  </TabContent>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default BranchPublicContact