import * as Yup from "yup";
import { FormikErrors } from "formik";
import { RequiredFieldValidationText, URLFieldValidationText } from "../../utils/Constant";

export interface CreatePostValidationProp {
    posttype:string;
    subject: string;
    desired_budget: string; 
    max_budget: string;
    country: string;
    description: string;
    users:string;
    employexperience: string; // updated to string to match form field type
    employperiodMonths: string; // updated to string to match form field type
    employscheduleType: string;
    employjobLocationType: string; 
  externallink: string;
  employsalary: string;
  jobreferenceid:string;
  notificationreferenceid:string

}

export const createPostInitialValue:CreatePostValidationProp ={
    posttype:"",
    subject:"",
    desired_budget: "", 
    max_budget:"",
    country:"",
    description:"",
    users:"",
    employexperience: "",
    employperiodMonths: "",
    employscheduleType: "",
    employjobLocationType: "", 
    externallink:'',
    employsalary:'',
    jobreferenceid:'',
    notificationreferenceid:''
    
}

// export const createPostSchema = Yup.object().shape({
//     posttype:Yup.string().required(), 
//     subject: Yup.string().required("Required").min(5), 
//     desired_budget: Yup.string().required("Required"), 
//     max_budget: Yup.string().required("Required"), 
//     country: Yup.string().required("Required"), 
//     description: Yup.string().required("Required"), 
//     users: Yup.string().required("Required"), 
// });
export const createPostSchema = Yup.object().shape({
    posttype: Yup.string().required("Post type is required"), 
    subject: Yup.string().required("Subject is required").min(5, "Subject must be at least 5 characters"), 
    desired_budget: Yup.number()
        .typeError("Desired Budget must be a number")
        .required("Desired Budget is required")
        .positive("Desired Budget must be a positive number"),
    max_budget: Yup.number()
        .typeError("Max Budget must be a number")
        .required("Max Budget is required")
        .min(Yup.ref('desired_budget'), "Max Budget must be equal to or greater than Desired Budget"),
    country: Yup.string().required("Country is required"), 
    description: Yup.string().required("Description is required"), 
    users: Yup.string().required("Users field is required"), 
     // Additional validations for new fields
     employexperience: Yup.number()
     .typeError("Experience must be a number")
     .required("Minimum years of experience is required")
     .min(0, "Experience can't be negative")
     .integer("Experience should be a whole number"),
     employperiodMonths: Yup.number()
     .typeError("Period in months must be a number")
     .required("Period in months is required")
     .min(0, "Period can't be negative")
     .integer("Period should be a whole number"),
    //  employscheduleType: Yup.string().required("Schedule type is required"),
    employscheduleType: Yup.array()
    .of(Yup.string().oneOf(['full-time', 'part-time', 'freelancing'], 'Invalid Schedule Type'))
    .min(1, 'At least one schedule type must be selected') // Ensures at least one type is selected
    .required('Schedule type is required'),
    employjobLocationType: Yup.string()
    .required("Job location type is required")
    .oneOf(['Remote', 'Specific Address', 'Job Location'], 'Invalid Job Location Type'), 
     externallink: Yup.string()
    .url(URLFieldValidationText)
    .required(RequiredFieldValidationText),
    employsalary:Yup.string().required("Salary is required"),
    jobreferenceid:Yup.string().required("Job Opening as a Reference is required"),
    notificationreferenceid:Yup.string().required("Job Opening as a Reference is required")

});
export interface PostFormValidationsProp {
    submitErrors: boolean;
    setSubmitError: (key:boolean)=>void
    errors: FormikErrors<CreatePostValidationProp>;
    values:any;
  }