import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FireworksDisplay } from "./Fireworks";

 const ResultsPopup = ({ isOpen, toggle, correctAnswersCount, totalQuestions,stopFireworks  }) => {
    let stars = "";
    let title = "";
    let showFireworks = false;
    const percentage = (correctAnswersCount / totalQuestions) * 100;

    if (percentage === 100) {
        stars = "⭐️⭐️⭐️⭐️⭐️"; // Gold Winner
        title = "Gold Winner! 🥇";
        showFireworks = true;
    } else if (percentage >= 70) {
        stars = "⭐️⭐️⭐️⭐️"; // Silver Winner
        title = "Silver Winner! 🥈";
    } else if (percentage >= 40) {
        stars = "⭐️⭐️"; // Bronze Winner
        title = "Bronze Winner! 🥉";
    } else {
        title = "Better luck next time!";
    }

    return (<>
        <Modal isOpen={isOpen}  toggle={() => {
                toggle();
                stopFireworks(); // Stop fireworks audio when modal closes
            }} className="modal-dialog-centered">
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody className="text-center">
                <h2>{stars}</h2>
                {/* You can add animations here */}
            </ModalBody>
        </Modal>
              {showFireworks && <FireworksDisplay />}
              {/* {stars = '⭐️⭐️⭐️⭐️⭐️' ? <FireworksDisplay />:''} */}
    </>
    );
};
export default ResultsPopup