import { useState } from "react";
import { Formik } from "formik";
import {
    StudyMaterialLessonFormValidationProp,
    studyMaterialLessonFormInitialValue,
    studyMaterialLessonFormValidationSchema,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { StudyMaterialAction } from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import { Link } from "react-router-dom";
import AddStudyMaterialLessonForm from "../StudyMaterialLesson/AddStudyMaterialLessonForm";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { H4, H5 } from "../../../../AbstractElements";
import { PostContent } from "../../../../utils/Constant";
import SimpleMdeReact from "react-simplemde-editor";
import Branches from "../../../CommonInput/Branches";
import { scheduleFormInitialValue, scheduleFormValidationSchema } from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import LessonField from "../../../CommonInput/LessonField";

const SecondLesson = () => {
    const [submitErrors, setSubmitError] = useState<boolean>(false);

    const handleSubmit = (values: StudyMaterialLessonFormValidationProp) => {
        console.log(values);
        setSubmitError(false);
    };
    const [activeCheckbox, setActiveCheckbox] = useState<"first" | "second">(
        "first"
    );

    const handleCheckboxChange = (checkbox: "first" | "second") => {
        // Toggle between checkboxes
        setActiveCheckbox(checkbox);
    };
    return (
        <> 
            <H4 className="mb-2">Add Lesson (optional)</H4>
            <Row className="my-2 px-3">
                <Col>
                    <div>
                        <div>
                            <div
                                className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                    activeCheckbox === "first"
                                        ? "transform-scale"
                                        : ""
                                }`}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={activeCheckbox === "first"}
                                        onChange={() =>
                                            handleCheckboxChange("first")
                                        }
                                    />
                                    <H5 className="ms-2">
                                        Select an Exsiting Lesson
                                    </H5>
                                </label>
                            </div>
                            <div
                                className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                    activeCheckbox === "second"
                                        ? "transform-scale"
                                        : ""
                                }`}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={activeCheckbox === "second"}
                                        onChange={() =>
                                            handleCheckboxChange("second")
                                        }
                                    />
                                    <H5 className="ms-2">
                                        Create a New Lesson
                                    </H5>
                                </label>
                            </div>
                        </div>

                        {/* Display content based on which checkbox is active */}
                        <div>
                            {activeCheckbox === "first" ? (
                                <div className="px-4 py-2"> 
                                    <Formik
                                        initialValues={scheduleFormInitialValue}
                                        onSubmit={handleSubmit}
                                        validationSchema={
                                            scheduleFormValidationSchema
                                        }>
                                        {({ errors }) => (
                                            <Form className="form-wizard">
                                                <Row className="align-items-center">
                                                    <Col
                                                        sm="12"
                                                        className="mb-3">
                                                        <LessonField
                                                            name="branch"
                                                            isMulti={true}
                                                            placeholder="Select"
                                                        />
                                                    </Col>
                                                    
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={
                                            studyMaterialLessonFormInitialValue
                                        }
                                        onSubmit={handleSubmit}
                                        validationSchema={
                                            studyMaterialLessonFormValidationSchema
                                        }>
                                        {({
                                            errors,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <AddStudyMaterialLessonForm
                                                submitErrors={submitErrors}
                                                setSubmitError={setSubmitError}
                                                errors={errors}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Formik>
                                     
                                </>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default SecondLesson;
